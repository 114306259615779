import React from 'react';
import { shape, string, oneOf } from 'prop-types';

/* @Components */
import { Container } from 'components/Container';
import { Helmet } from 'react-helmet';
import { Loading } from './submodules/Loading';
import { Error } from './submodules/Error';
import { Success } from './submodules/Success';
import { NotMacUser } from './submodules/NotMacUser';
import { BlogInfo } from './submodules/BlogInfo';

/* @Images */
import background from 'images/background.svg';
import cmmx from 'images/cmmx_new.png';
import cmmx2x from 'images/cmmx_new@2x.png';
import cmm5 from 'images/cmm5.png';
import cmm52x from 'images/cmm5x2.png';
import cmp from 'images/cleanmyphone.png';
import cmp2x from 'images/cleanmyphone2x.png';
import macpaw from 'images/logo_macpaw.png';
import macpaw2x from 'images/logo_macpaw@2x.png';

/* @Styles */
import './main.scss';

const propTypes = {
  urlInfo: shape({
    productName: string,
    mainCta: shape({
      title: string,
      url: string,
    }),
    secondaryCta: shape({
      title: string,
      url: string,
    }),
    params: shape({
      macOs: shape({
        deepLink: string,
      }),
      seo: shape({
        description: string,
        title: string,
      }),
    }),
  }),
  step: oneOf(['idle', 'loading', 'osNameCheck', 'deepLinkCheck', 'error', 'notSupportedBrowser', 'openDeepLink']),
};

export const MainComponent = ({ step, urlInfo }) => {
  const isShowLoader = !['notSupportedBrowser', 'error', 'openDeepLink'].includes(step);
  const isShowBlogInfo = !isShowLoader;
  const isCmmx = ['CleanMyMac X Site', 'CleanMyMac X MAS', 'CleanMyMac X Chinese', 'CleanMyMac X Setapp'].includes(
    urlInfo?.productName
  );
  const isCmm5 = urlInfo?.productName === 'CleanMyMac 5';
  const isCmp = urlInfo?.productName === 'CleanMyPhone';
  const isOther = !isCmm5 && !isCmmx && !isCmp;

  return (
    <>
      <Helmet>
        {urlInfo?.params?.seo?.title && <meta property="og:title" content={urlInfo.params.seo.title} />}
        {urlInfo?.params?.seo?.description && (
          <meta property="og:description" content={urlInfo.params.seo.description} />
        )}
      </Helmet>
      <img src={background} className="background" width="840" alt="Background" aria-hidden="true" />
      <Container className="main-container">
        {isCmmx && <img src={cmmx} srcSet={`${cmmx2x} 2x`} width="156" height="156" alt="CleanMyMac X Logo" />}
        {isCmm5 && <img src={cmm5} srcSet={`${cmm52x} 2x`} width="156" height="156" alt="CleanMyMac 5 Logo" />}
        {isCmp && <img src={cmp} srcSet={`${cmp2x} 2x`} width="156" height="156" alt="CleanMyPhone Logo" />}
        {isOther && <img src={macpaw} srcSet={`${macpaw2x} 2x`} width="156" height="156" alt="MacPaw Logo" />}
        {step === 'openDeepLink' && (
          <Success
            productName={urlInfo?.productName}
            deepLink={{ url: urlInfo?.params?.macOs?.deepLink, title: urlInfo?.deepLinkCta }}
          />
        )}
        {step === 'notSupportedBrowser' && <NotMacUser />}
        {step === 'error' && <Error productName={urlInfo?.productName} fallbackCta={urlInfo?.mainCta} />}
        {isShowLoader && <Loading />}
        {isShowBlogInfo && <BlogInfo productName={urlInfo?.productName} secondaryCta={urlInfo?.secondaryCta} />}
      </Container>
    </>
  );
};

MainComponent.propTypes = propTypes;
