import React, { useEffect, useContext } from 'react';

/* @Components */
import { NotMacUserComponent } from './NotMacUserComponent';

/* @Context */
import { UserAppInfoContext, USER_APP_INFO_STATUS } from 'contexts/UserAppInfoContext';

export const NotMacUserContainer = () => {
  const { setUserAppInfoStatus } = useContext(UserAppInfoContext);

  useEffect(() => {
    setUserAppInfoStatus(USER_APP_INFO_STATUS.NOT_INSTALLED);
  }, [setUserAppInfoStatus]);

  return <NotMacUserComponent />;
};
