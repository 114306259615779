import React from 'react';

/* @Hooks */
import { useIntl } from 'react-intl';

export const NotMacUserComponent = () => {
  const { formatMessage: trans } = useIntl();

  return (
    <>
      <h1>{trans({ id: 'linksWorkOnMac' })}</h1>
      <h2>
        {trans({ id: 'triedOpenAppOnPC' })}
        <br />
        {trans({ id: 'tryAgainOnMac' })}
      </h2>
    </>
  );
};
